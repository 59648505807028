<template>
  <b-container>
    <h4>Test notifiche utente</h4>
    <b-row>
      <b-col sm="4">
        <p>Codice chiamata</p>
        <b-form-select
          v-model="httpStatusCode"
          :options="options"
        ></b-form-select>
      </b-col>
      <b-col sm="2">
        <p style="color:transparent;">Invio</p>
        <b-button
          class="btn-block"
          @click="send_request"
        >Invio</b-button>
      </b-col>
      <b-col sm="6">
        <p style="color:transparent;">Risultato</p>
        <p>
          {{message}}
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { service_handler } from '@/services'
export default {
  name: 'notifications',
  data() {
    return {
      message: 'Invia una richiesta',
      httpStatusCode: 200,
      options: [
        { value: 200, text: '200' },
        { value: 204, text: '204' },
        { value: 307, text: '307' },
        { value: 401, text: '401' },
        { value: 409, text: '409' },
        { value: 500, text: '500 (modal:true)' },
        { value: 501, text: '501 (modal:false)' },
      ]
    }
  },
  methods:
  {
    send_request() {
      let parameters = {
        httpStatusCode: this.httpStatusCode
      }
      service_handler(
        'testNotifications',
        parameters,
        { modal: (this.httpStatusCode !== 501) }
      )
        .then(
          (success) => {
            this.log('[notifications.vue] - success')
            this.log(success.data.msg)
            this.message = success.data.msg
          },
          (error) => {
            this.log('[notifications.vue] - error')
            this.log(error.response.data)
            this.message = '[' + error.response.data.status + '] - ' + error.response.data.msg
          }
        )

    }
  }
}
</script>